.FileManager-SideBar .SideBar-TreeNode {

}

.fileManager {
    height: 700px;
    padding: 0 10px 10px;
    min-width: 250px;
    width: 250px;
    overflow-y: scroll;
    white-space: nowrap;
}

.fileManager.dark {
    background: #282c34;
    color: #5ca4e0;
}

.fileManager.light {
    background: #ffffff;
    color: #000000;
}

.TreeNode-Name.TreeNode-Current {
    color: #000000;
    background-color: rgba(0, 0, 255, 0.1);
}

.dark .TreeNode-Name.TreeNode-Current {
    color: #000000;
    background-color: #5ca4e0;
}

.SideBar-TreeNode .TreeNode-Name {
    height: 28px;
    line-height: 28px;
    cursor: pointer;
}

.SideBar-TreeNode .TreeNode-Children {

}