@import '~bootstrap/dist/css/bootstrap.min.css';
@import './css/buttons.css';
@import './css/common.css';
@import './css/colors.css';
@import './css/font.css';
@import './css/layout.css';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300&display=swap');
@import './css/navBar.css';
@import './css/sidebar.css';
@import './css/footer.css';
@import './css/notfound.css';
@import './css/flowy.css';
@import './css/arch.css';
@import './css/docs.css';
@import './css/tests.css';
@import './css/tree.css';

:root {
	--navBarHeight: 54px;
	--footerHeight: 60px;
	--footerBgColor: #f3f4f6;
}

* {
	font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body,
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	position: relative;
}
.custom-lint-panel {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  background-color: #1e1e1e !important; /* Фон панели */
  color: #d4d4d4 !important; /* Цвет текста */
  border-top: 1px solid #333 !important; /* Верхняя граница панели */
  padding: 10px !important; /* Отступы внутри панели */
  display: flex !important;
  flex-direction: column !important;
  z-index: 1000 !important; /* Поверх всех элементов */
  max-height: 300px !important; /* Максимальная высота панели */
  overflow-y: auto !important; /* Прокрутка по вертикали, если ошибок много */
}

.custom-lint-panel .cm-panel-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 10px !important; /* Отступ между заголовком и списком ошибок */
}

.custom-lint-panel .cm-panel-title {
  font-size: 16px !important; /* Размер шрифта заголовка */
  font-weight: bold !important; /* Жирный шрифт заголовка */
}

.custom-lint-panel .cm-diagnostic {
  font-family: "Consolas", "Courier New", monospace !important; /* Шрифт, похожий на терминал */
  font-size: 14px !important; /* Размер шрифта */
  padding: 4px 0 !important; /* Отступы между строками ошибок */
}

.custom-lint-panel .cm-diagnostic-error {
  color: #e06c75 !important; /* Цвет ошибок (красный) */
}

.custom-lint-panel .cm-diagnosticSource {
  font-size: 12px !important; /* Размер шрифта источника ошибки */
  color: #c678dd !important; /* Цвет источника ошибки (фиолетовый) */
}

.custom-lint-panel .cm-panel-close-button {
  background-color: #333 !important; /* Фон кнопки закрытия */
  color: #d4d4d4 !important; /* Цвет текста кнопки закрытия */
  border: none !important; /* Убрать рамку */
  cursor: pointer !important; /* Указатель при наведении */
  padding: 5px 10px !important; /* Отступы внутри кнопки */
  font-size: 14px !important; /* Размер шрифта кнопки */
}

.custom-lint-panel .cm-panel-close-button:hover {
  background-color: #444 !important; /* Цвет фона при наведении */
}
